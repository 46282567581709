import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoginScreen, HomeScreen } from "../screens/unAuthenticated";

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      {/* Adicione outras rotas públicas aqui */}
    </Routes>
  );
};

export default PublicRoutes;
