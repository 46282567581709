import React, { useEffect, useState } from "react";
import { UseSimpleFetch } from "../../../hooks/useSimpleFetch";

export const ListScreen: React.FC = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await UseSimpleFetch("/banks/v1");
      setData(response.data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <p>Dados da API:</p>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};
