import React from "react";
import { useAuth } from "../../../context";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, Button } from "@mui/material";

export const DashboardScreen: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Implementar a lógica de logout
    navigate("/");
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Painel de Controle
        </Typography>
        <Typography paragraph>Bem-vindo ao seu painel. Aqui você pode gerenciar suas configurações.</Typography>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Sair
        </Button>{" "}
        <Button variant="contained" color="primary" onClick={() => navigate("/list")}>
          List
        </Button>
      </Box>
    </Container>
  );
};
