import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context";
import { Button, TextField, Container, Typography, Box } from "@mui/material";

export const LoginScreen: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();

  const handleLogin = async () => {
    try {
      await auth.login(username, password);
      navigate("/dashboard"); // Redireciona para o Dashboard após o login
    } catch (error) {
      console.error("Erro de login", error);
    }
  };
  return (
    <Container maxWidth="xs">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        <TextField
          label="Usuário"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Senha"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
          Entrar
        </Button>
      </Box>
    </Container>
  );
};
