import React, { createContext, useState, useContext, ReactNode } from "react";

// Definindo o tipo para o estado de autenticação.
interface AuthState {
  user: any; // Substitua 'any' pelo tipo específico do seu usuário
  token: string | null;
}

// Definindo o tipo para o contexto de autenticação.
interface AuthContextType {
  authState: AuthState;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

// Criando o contexto com um valor inicial padrão.
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Definindo o tipo para as props do AuthProvider.
interface AuthProviderProps {
  children: ReactNode;
}

// Implementando o AuthProvider.
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({ user: null, token: null });

  // Implementação simplificada do login
  const login = async (username: string, password: string) => {
    // Simula um login, substitua com sua lógica de autenticação
    setAuthState({ user: { name: "Usuário Exemplo" }, token: "token-fictício" });
  };

  // Implementação simplificada do logout
  const logout = () => {
    setAuthState({ user: null, token: null });
  };

  return <AuthContext.Provider value={{ authState, login, logout }}>{children}</AuthContext.Provider>;
};

// Hook personalizado para usar o contexto de autenticação.
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
};
