import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

export const HomeScreen: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Página Inicial
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/login">
          Entrar
        </Button>
      </Box>
    </Container>
  );
};
