import React from "react";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { useAuth } from "./context";

const App: React.FC = () => {
  const { authState } = useAuth();

  return <BrowserRouter>{authState.user ? <PrivateRoutes /> : <PublicRoutes />}</BrowserRouter>;
};
export default App;
