import { api } from "../services/api";

type PropsFetch = {
  method: "put" | "post" | "patch" | "delete" | "get";
  params?: any;
  headers?: any;
};

export const UseSimpleFetch = async (url: string, props?: PropsFetch) => {
  try {
    const response = await api[props ? props.method : "get"](
      url,
      props ? props.params : {},
      props ? props.headers : {}
    );
    return response;
  } catch (error: any) {
    if (error.response) {
      return error?.response;
    }
  }
};
