import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { DashboardScreen, ListScreen } from "../screens/authenticated";
import { useAuth } from "../context";

const PrivateRoutes: React.FC = () => {
  const { authState } = useAuth();

  return (
    <Routes>
      {authState.user ? (
        <>
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/list" element={<ListScreen />} />
          {/* Adicione outras rotas privadas aqui */}
        </>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
};

export default PrivateRoutes;
